import React, { useState, useRef, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Link from 'next/link';
import Smartlook from 'smartlook-client';

import { BackgroundImage } from 'components/BackgroundImage';
import Trustpilot from 'public/assets/logotype/trustpilot.svg';
import { ClientApiService } from 'services/ClientApiService';
import { initialCarLoanData } from 'components/CarLoanCalculator/data/initialCarLoanData';
import { ICouponData } from 'types/CouponCode';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import EcsterLogo from 'public/assets/logotype/ecster.svg';
import NordeaLogo from 'public/assets/logotype/nordeafinance.svg';
import { isLoggedIn } from 'lib/auth/AuthContext';
import useUser from 'lib/auth/useUser';
import { loanDataLayerPush } from 'utils/loanDataLayerPush';
import { setRememberMeCookie } from 'lib/auth/setRememberMeCookie';
import { handleNotAuthenticatedUser } from 'utils/handleNotAuthenticatedUser';
import { LoanType } from 'enums/LoanType';

import { CalculateWithActions } from './CalculateWithActions';
import styles from './Calculate.module.scss';
import { preparePostLoansPayload, preparePostUsersPayload } from './actions/preparePayload';
import { fetchCouponCode } from './actions/fetchCouponCode';

const getAccountExists = (errorMessage: string) =>
  errorMessage === 'Kontot finns redan eller e-post/personummer används för ett annat konto.';

interface IProps {
  imageSrc?: string;
  isInverted?: boolean;
  loanType: LoanType;
  hideHeadline?: boolean;
  hideImage?: boolean;
  initialData?: Record<string, unknown>;
}

export const Calculate: React.FC<IProps> = ({
  isInverted = true,
  imageSrc,
  loanType,
  hideHeadline,
  hideImage,
  initialData = {},
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useUser();
  const [couponCodeData, setCouponCodeData] = useState<ICouponData | null>(null);
  const methods = useForm({
    defaultValues: { ...initialCarLoanData, ...initialData },
  });

  async function fetchInitalCoupon(campaignCode: string): Promise<void> {
    const couponResponse = await fetchCouponCode(campaignCode, 'campaign-code');
    setCouponCodeData(couponResponse);
  }

  useEffect(() => {
    if (initialData['campaign-code']) {
      fetchInitalCoupon(initialData['campaign-code'] as string);
    }
  }, []);
  const scollToRef = useRef<null | HTMLDivElement>(null);
  const showImage = !hideImage && imageSrc;

  const handleOnBlur = async (e: React.FormEvent<HTMLFormElement>) => {
    const { target } = e;
    const { value, name } = target as HTMLInputElement;

    /**
     * update coupon code filed from here
     * we probably need setError from react hook form
     */
    const couponResponse = await fetchCouponCode(value, name);

    setCouponCodeData(couponResponse);
  };

  const onSubmit = async (calculatorData: {}) => {
    Smartlook.track('loan-application', {
      step: 'loan-submitted',
    });
    if (!isLoggedIn(user)) {
      const response = await ClientApiService.proxyRequest({
        method: 'postUsers',
        payload: preparePostUsersPayload(calculatorData),
      });
      const responseJson = await response.json();

      if (response.status !== 200) {
        if (getAccountExists(responseJson.body.message)) {
          /**
           * @todo responseJson will tell us if "Konto finns redan?" in flow chart
           * Meaning that the email and or phone is already in use, (possible even talking about the Ssn?).
           * We need to handle this, it should bring up a modal or similar – prefilled with the Ssn so that the
           * user can attempt a login using that Ssn and continue its journey.
           * @note can we route to login page and pass the Ssn?
           */

          // if this error is handled as described above, we might as well remove this general-error
          methods.setError('general-error', {
            type: 'response',
            message: responseJson.body.message,
          });

          // @ts-ignore
          setRememberMeCookie(calculatorData.ssn, false);

          return;
        }

        methods.setError('general-error', {
          type: 'response',
          message: responseJson.body.message,
        });

        return;
      }
    }

    const response = await ClientApiService.proxyRequest({
      method: 'postLoans',
      payload: preparePostLoansPayload(calculatorData, loanType),
    });
    const responseJson = await response.json();

    handleNotAuthenticatedUser(response.status, responseJson.body.not_authenticated);

    // handle error
    if (response.status !== 200) {
      methods.setError('general-error', {
        type: 'response',
        message: responseJson.body.message,
      });

      return;
    }

    // @ts-ignore
    loanDataLayerPush(responseJson.body.payload.responseStatus, calculatorData['total-cost']);

    // if (user?.isLoggedIn) {
    //   await router.push('/app/loan');
    //  }
    setIsSuccess(true);
    if (scollToRef.current) {
      scollToRef.current.scrollIntoView();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} onBlur={handleOnBlur}>
        <section
          className={`${
            hideImage ? styles['calculate-section-no-image'] : styles['calculate-section']
          } ${isInverted ? styles.inverted : ''}`}
        >
          {!isSuccess ? (
            <div className={styles['calculator-container']}>
              {!hideHeadline && (
                <>
                  <h2 className={styles.heading}>Låna till din nya bil</h2>
                  <p className={styles.description}>
                    Samma billån som hos bilhandlaren när du köper begagnad bil. Ansök om billån och
                    boka tid med Blipp för experthjälp.
                  </p>
                </>
              )}

              <CalculateWithActions couponData={couponCodeData || undefined} loanType={loanType} />

              <span className={styles['submit-description']}>
                Om du lånar 200 000 kr i 5 år med en rörlig ränta på 7,99%, aviavgift 55 kr per
                månad och uppläggningsavgift 695 kr blir totalbeloppet att betala tillbaka 247 255
                kr, varav 47 255 kr är avgifter och räntekostnad. Antalet betalningar är 60 st och
                den effektiva räntan är 9,07%. Minsta kontantinsats 20%. <br />
                Exempel framtaget 2023-08-03.
              </span>
            </div>
          ) : (
            <div ref={scollToRef} className={styles['success-container']}>
              {loanType === LoanType.Ecster ? (
                <div className={styles.message}>
                  <EcsterLogo />
                  <h2>Nu har du ditt svar!</h2>
                  <p>
                    Ecster har nu svarat på din ansökan och du kan logga in med mobilt BankID för
                    att se ditt svar.
                  </p>
                </div>
              ) : (
                <div className={styles.message}>
                  <NordeaLogo />
                  <h2>Tack för din låneansökan!</h2>
                  <p>Vi kommer att meddela dig så snart ansökan är hanterad och klar.</p>

                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.PrimaryMintSweet}
                    size={ButtonSize.Large}
                    href="/app/loan"
                  >
                    Följ din ansökan på Mitt Blipp
                  </Button>
                </div>
              )}
            </div>
          )}
          {showImage && (
            <div className={styles['image-container']}>
              <BackgroundImage src={imageSrc} alt="Loan calculator" />
              <div className={styles.content}>
                <Link href="https://se.trustpilot.com/review/blipp.se">
                  <a target="_blank">
                    <h3>Våra kunder tycker att vi är</h3>
                    <h2>4.7 / 5.0</h2>
                    <Trustpilot className={styles.logo} />
                  </a>
                </Link>
              </div>
            </div>
          )}
        </section>
      </form>
    </FormProvider>
  );
};
