import {
  BLANCO_INTEREST,
  INTEREST_DEFAULT,
  WARRANTY_DEFAULT,
  calculateDownpaymentFromPercent,
  calculateMonthlyCostWInterest,
} from 'components/CarLoanCalculator/utils/calculateMonthlyCost';
import {
  getMonths,
  getResidualPercent,
} from 'components/CarLoanCalculator/utils/getResidualPercent';
import { BLIPP_FEE_PERCENT } from 'content/globalConstants';
import { LoanType } from 'enums/LoanType';
import { VehicleType } from 'enums/VehicleType';
import { privateKycQuestions } from 'utils/kycData';
import { ICoupon } from 'types/CouponCode';

export const preparePostLoansPayload = (data: any, loanType: LoanType) => {
  const {
    investment_percent: investmentPercent,
    'total-cost': totalCost,
    'total-month-cost': totalMonthCost,
    duration,
    'campaign-code': campaignCode,
    terms,
    licensePlate,
    residual_percent: residualPercent,
    'campaign-code-data': campaignCodeData,
  } = data;

  const kycValuesAndQuestions = privateKycQuestions.reduce((acc, question) => {
    // eslint-disable-next-line
    acc[question.name] = {
      question: question.question,
      answer: data[question.name],
    };
    return acc;
  }, {});

  const getInterest = (coupon: ICoupon | undefined) =>
    coupon?.percentReduction?.active === true && coupon.percentReduction.value
      ? (INTEREST_DEFAULT - coupon.percentReduction.value) * 100
      : (INTEREST_DEFAULT * 100).toFixed(2);

  const totalCostWithFees =
    totalCost +
    (totalCost * BLIPP_FEE_PERCENT) / 100 +
    (data.blippinsurance ? WARRANTY_DEFAULT : 0);

  const investment = calculateDownpaymentFromPercent(totalCostWithFees, investmentPercent);
  const activeResidualPercent =
    typeof residualPercent === 'number'
      ? residualPercent / 100
      : getResidualPercent(getMonths(duration), loanType);

  const monthlySaved =
    (calculateMonthlyCostWInterest(
      totalCost,
      investment,
      getMonths(duration),
      activeResidualPercent,
      BLANCO_INTEREST
    ) -
      totalMonthCost) *
    getMonths(duration);

  return {
    amount: totalCost,
    terms_and_conditions: !!terms,
    vehicle_type: VehicleType.Car,
    months: getMonths(duration),
    residual_percent: activeResidualPercent,
    monthly_cost: totalMonthCost,
    down_payment: investment,
    money_saved: monthlySaved,
    coupon_code: campaignCode || null,
    loan_type: loanType,
    interest_rate: getInterest(campaignCodeData),
    // interest_rate: `${(INTEREST_DEFAULT * 100).toFixed(2)}`,
    registration_number: licensePlate || '',
    ...(data.blippinsurance ? { fee: WARRANTY_DEFAULT } : {}),
    blipp_fee_percent: BLIPP_FEE_PERCENT,
    amount_with_blipp_fee: totalCost + (totalCost * BLIPP_FEE_PERCENT) / 100,
    additional_data: kycValuesAndQuestions,
  };
};

export const preparePostUsersPayload = (data: any) => {
  const { email, phone, ssn, terms, 'total-cost': totalCost, 'campaign-code': campaignCode } = data;

  return {
    phone_number: phone,
    ssn: parseInt(ssn, 10),
    order_type: 'loan',
    email,
    terms_and_conditions: !!terms,
    notification_via_blipp: true,
    optimize: false,
    price: totalCost,
    code: campaignCode || null,
  };
};
